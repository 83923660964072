import { ComponentProps, useCallback, useRef } from 'react'
import { AnyVariables } from 'urql'
import { Input } from '~/core/ui/Input'
import { InputRightElement } from '~/core/ui/InputElement'
import { InputGroup } from '~/core/ui/InputGroup'
import { ISelectOption } from '~/core/ui/Select'
import ComputeInputRightPadding from '~/features/placements/components/ComputeInputRightPadding'
import { createFilterControl, FilterControl } from '.'
import FilterItemCombobox, {
  FilterItemComboboxPropTypeDocumentNode
} from './FilterItemCombobox'

export const FilterItemSearchOutFocus = createFilterControl(
  (
    props: ComponentProps<typeof Input> &
      Partial<FilterControl<string>> & {
        config: Omit<
          FilterItemComboboxPropTypeDocumentNode<any, AnyVariables>,
          'value' | 'onChange'
        > & {
          onChange?: (newValue: ISelectOption) => void
        }
      }
  ) => {
    const onFilterSubmitRef = useRef<() => void>()

    const onBlur = useCallback(() => {
      onFilterSubmitRef.current = props.onFilterSubmit
      onFilterSubmitRef?.current && onFilterSubmitRef?.current()
    }, [props.onFilterSubmit])

    return (
      <InputGroup className="flex" configPadding={{ left: '', right: '' }}>
        <ComputeInputRightPadding>
          {({ inputStyle, rightInputRef, createOnPaddingChange }) => (
            <>
              <Input
                {...props}
                type="number"
                config={undefined}
                style={inputStyle}
                value={props.value || ''}
                onBlur={onBlur}
              />
              <InputRightElement className="right-0 top-0">
                <div ref={rightInputRef}>
                  <FilterItemCombobox
                    {...props.config}
                    // @ts-ignore
                    onChange={createOnPaddingChange(
                      (newValue: ISelectOption) => {
                        props.config?.onChange &&
                          props.config.onChange(newValue)
                      }
                    )}
                  />
                </div>
              </InputRightElement>
            </>
          )}
        </ComputeInputRightPadding>
      </InputGroup>
    )
  }
)
