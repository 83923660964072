import { ComponentProps, useMemo } from 'react'
import { AnyVariables, DocumentInput } from 'urql'
import { NativeSelect } from '~/core/ui/NativeSelect'
import { SelectProps } from '~/core/ui/Select'
import {
  createFilterControl,
  createOptionFetcher,
  FilterControlItemType
} from '.'

export interface FilterItemSelectPropTypeDocumentNode<
  Data = any,
  Variables extends AnyVariables = AnyVariables
> extends ComponentProps<typeof NativeSelect>,
    FilterControlItemType {
  optionsFromDocumentNode: {
    documentNode: DocumentInput<Data, Variables>
    variable: ((search: string) => Variables) | Variables
    mapping: (data: Data) => {
      value: string
      supportingObj: {
        name: string
      }
    }[]
  }
}

export const FilterItemSelect = <
  Data = any,
  Variables extends AnyVariables = AnyVariables
>(
  props: FilterItemSelectPropTypeDocumentNode<Data, Variables> & {
    triggerFilterChange?: boolean
  }
) => {
  const { triggerFilterChange = true } = props
  const Control = useMemo(() => {
    const optionsFromDocumentNode = props.optionsFromDocumentNode
    return createFilterControl<
      FilterItemSelectPropTypeDocumentNode<Data, Variables>
    >(
      //@ts-ignore
      optionsFromDocumentNode
        ? createOptionFetcher((props) => {
            return (
              <NativeSelect
                {...props}
                // async
                // loadOptions={fetchNewOption}
                value={props.value || null}
                onChange={(value) => {
                  return props.onChange(value, { triggerFilterChange })
                }}
                options={props.options}
              />
            )
          })
        : (props) => (
            <NativeSelect
              {...props}
              value={props.value}
              onChange={(value) =>
                props.onChange(value, { triggerFilterChange })
              }
            />
          )
    )
  }, [])
  return <Control {...props} />
}
