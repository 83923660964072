import { gql } from 'urql'

export type QueryTenantLocationVariable = {
  limit?: number
  page?: number
  search?: string
  tenantSlug?: string
}
const QueryPublicLocation = gql<{
  publicLocationsList: {
    collection: {
      name: string
      address: string
      country: string
      state: string
      countryStateId: number
    }[]
    metadata: { totalCount: number }
  }
}>`
  query ($page: Int, $limit: Int, $tenantSlug: String!, $search: String) {
    publicLocationsList(
      tenantSlug: $tenantSlug
      page: $page
      limit: $limit
      search: $search
    ) {
      collection {
        name
        address
        country
        state
        countryStateId
      }
      metadata {
        totalCount
      }
    }
  }
`

export default QueryPublicLocation
