import { JOB_STATUS_ENUM } from '../utilities/enum'

type IValueShareJobWithCondition = {
  sharePublic?: boolean
  shareInternal?: boolean
  jobList?: {
    shareIcon?: boolean
    moreShare?: boolean
    viewJob?: boolean
  }
  recommendation?: {
    shareBtn?: boolean
  }
}

const useShareJobLogic = () => {
  const valueShareJobWithCondition = ({
    enablingReferral = false,
    enablingCareerSite = false,
    enableJobReferral = false,
    jobStatus = ''
  }: {
    enablingReferral: boolean
    enablingCareerSite: boolean
    enableJobReferral: boolean
    jobStatus: string
  }): IValueShareJobWithCondition => {
    if (
      enablingReferral === true &&
      enableJobReferral === true &&
      enablingCareerSite === true
    ) {
      if (jobStatus === JOB_STATUS_ENUM.publish) {
        return {
          sharePublic: true,
          shareInternal: true,
          jobList: {
            shareIcon: true,
            moreShare: true,
            viewJob: true
          },
          recommendation: {
            shareBtn: true
          }
        }
      }
      if (jobStatus === JOB_STATUS_ENUM.internal) {
        return {
          sharePublic: false,
          shareInternal: true,
          jobList: {
            shareIcon: true,
            moreShare: true,
            viewJob: false
          },
          recommendation: {
            shareBtn: true
          }
        }
      }
    }
    if (
      enablingReferral === true &&
      enableJobReferral === true &&
      enablingCareerSite === false
    ) {
      if (jobStatus === JOB_STATUS_ENUM.publish) {
        return {
          sharePublic: false,
          shareInternal: true,
          jobList: {
            shareIcon: true,
            moreShare: true,
            viewJob: false
          },
          recommendation: {
            shareBtn: true
          }
        }
      }
      if (jobStatus === JOB_STATUS_ENUM.internal) {
        return {
          sharePublic: false,
          shareInternal: true,
          jobList: {
            shareIcon: true,
            moreShare: true,
            viewJob: false
          },
          recommendation: {
            shareBtn: true
          }
        }
      }
    }
    if (
      ((enablingReferral === true && enableJobReferral === false) ||
        (enablingReferral === false && enableJobReferral === true)) &&
      enablingCareerSite === true
    ) {
      if (jobStatus === JOB_STATUS_ENUM.publish) {
        return {
          sharePublic: true,
          shareInternal: false,
          jobList: {
            shareIcon: true,
            moreShare: true,
            viewJob: true
          },
          recommendation: {
            shareBtn: true
          }
        }
      }
      if (jobStatus === JOB_STATUS_ENUM.internal) {
        return {
          sharePublic: false,
          shareInternal: false,
          jobList: {
            shareIcon: false,
            moreShare: false,
            viewJob: false
          },
          recommendation: {
            shareBtn: false
          }
        }
      }
    }
    if (
      enablingReferral === true &&
      enableJobReferral === false &&
      enablingCareerSite === false
    ) {
      if (jobStatus === JOB_STATUS_ENUM.publish) {
        return {
          sharePublic: false,
          shareInternal: false,
          jobList: {
            shareIcon: false,
            moreShare: false,
            viewJob: false
          },
          recommendation: {
            shareBtn: false
          }
        }
      }
      if (jobStatus === JOB_STATUS_ENUM.internal) {
        return {
          sharePublic: false,
          shareInternal: false,
          jobList: {
            shareIcon: false,
            moreShare: false,
            viewJob: false
          },
          recommendation: {
            shareBtn: false
          }
        }
      }
    }
    if (
      enablingReferral === false &&
      enableJobReferral === false &&
      enablingCareerSite === true
    ) {
      if (jobStatus === JOB_STATUS_ENUM.publish) {
        return {
          sharePublic: true,
          shareInternal: false,
          jobList: {
            shareIcon: true,
            moreShare: true,
            viewJob: true
          },
          recommendation: {
            shareBtn: true
          }
        }
      }
      if (jobStatus === JOB_STATUS_ENUM.internal) {
        return {
          sharePublic: false,
          shareInternal: false,
          jobList: {
            shareIcon: false,
            moreShare: false,
            viewJob: false
          },
          recommendation: {
            shareBtn: false
          }
        }
      }
    }
    if (
      enablingReferral === false &&
      enableJobReferral === false &&
      enablingCareerSite === false
    ) {
      if (jobStatus === JOB_STATUS_ENUM.publish) {
        return {
          sharePublic: false,
          shareInternal: false,
          jobList: {
            shareIcon: false,
            moreShare: false,
            viewJob: false
          },
          recommendation: {
            shareBtn: false
          }
        }
      }
      if (jobStatus === JOB_STATUS_ENUM.internal) {
        return {
          sharePublic: false,
          shareInternal: false,
          jobList: {
            shareIcon: false,
            moreShare: false,
            viewJob: false
          },
          recommendation: {
            shareBtn: false
          }
        }
      }
    }
    return {
      sharePublic: false,
      shareInternal: false,
      jobList: {
        shareIcon: false,
        moreShare: false
      },
      recommendation: {
        shareBtn: false
      }
    }
  }
  return {
    valueShareJobWithCondition
  }
}

export default useShareJobLogic
