import { useContext } from 'react'
import { Button, ButtonProps } from '~/core/ui/Button'
import { FilterDataHolderContext } from '../Filter'

export const FilterButton = (
  props: ButtonProps & {
    isSubmit?: boolean
    defaultFilter?: any
  }
) => {
  const { isSubmit, defaultFilter, ...otherProps } = props
  const { onClickSubmit, onClearFilterSubmit } = useContext(
    FilterDataHolderContext
  )

  return (
    <Button
      {...otherProps}
      onClick={(event) => {
        if (isSubmit) {
          onClickSubmit && onClickSubmit()
        } else {
          onClearFilterSubmit && onClearFilterSubmit(defaultFilter)
        }
        if (props.onClick) {
          props.onClick(event)
        }
      }}
    />
  )
}
