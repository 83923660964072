import { ReactElement, useMemo } from 'react'
import { createFilterControl, FilterControlItemType } from '.'

type ItemProp<T> = {
  children: (props: {
    value: T
    onChange: (value: T) => void
    onFilterSubmit?: () => void
  }) => ReactElement
  triggerFilterChange?: boolean
} & FilterControlItemType

export const FilterItem = <T extends unknown>(props: ItemProp<T>) => {
  const { triggerFilterChange = true } = props
  const Component = useMemo(
    () =>
      createFilterControl<ItemProp<T>, T>((props) => {
        return props.children({
          value: props.value,
          onChange: (value) => props.onChange(value, { triggerFilterChange }),
          onFilterSubmit: props.onFilterSubmit
        })
      }),
    [props?.name]
  )
  return <Component {...props} />
}
export default FilterItem
