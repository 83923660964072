import { gql } from 'urql'

const QueryTenantReferralSettingShow = gql`
  query {
    tenantReferralSettingShow {
      id
      values
      attachments {
        name
        id
        file
        blobs
      }
      departments {
        id
        name
        subordinates {
          id
          name
        }
        parent {
          id
          name
        }
      }
    }
  }
`

export default QueryTenantReferralSettingShow
