import {
  ChangeEvent,
  createContext,
  ReactElement,
  useCallback,
  useEffect,
  useState
} from 'react'
import { FilterButton } from './control/FilterButton'
import FilterItem from './control/FilterItem'
import FilterItemCombobox from './control/FilterItemCombobox'
import { FilterItemMultipleSelect } from './control/FilterItemMultipleSelect'
import { FilterItemRangeDatePicker } from './control/FilterItemRangeDatePicker'
import { FilterItemSearchOutFocus } from './control/FilterItemSearchOutFocus'
import { FilterItemSearchText } from './control/FilterItemSearchText'
import { FilterItemSelect } from './control/FilterItemSelect'

type OptionsParamType = {
  triggerFilterChange: boolean
}

export const FilterDataHolderContext = createContext<{
  onFilterItemChange?: (
    name: string,
    value: any,
    options?: OptionsParamType
  ) => void
  getFilterItemValue?: (name: string) => any
  onFilterSubmit?: () => void
  onClickSubmit?: () => void
  onClearFilterSubmit?: (defaultFilter: any) => void
}>({})
const Filter = <F extends { [key: string]: any }>({
  value,
  onChange,
  children
}: {
  value?: F
  onChange: (filter: F | undefined, name?: string) => void
  children: ReactElement | null
}) => {
  const [filter, setFilter] = useState<F | undefined>(value)
  const getFilterItemValue = useCallback(
    (name: string) => {
      return filter && filter[name]
    },
    [filter]
  )
  const onFilterItemChange = useCallback(
    (name: string, value: any, options?: OptionsParamType) => {
      const newFilter: any = { ...filter, [name]: value, isFilterTouched: true }
      setFilter(newFilter)
      if (options?.triggerFilterChange) {
        onChange(newFilter, name)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filter]
  )

  const onFilterSubmit = useCallback(
    (e?: ChangeEvent<any>) => {
      e?.preventDefault()
      onChange(filter)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filter]
  )

  const onClickSubmit = useCallback(
    () => {
      const newFilter: any = { ...filter, isFilterTouched: true }
      onChange(newFilter)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filter]
  )

  const onClearFilterSubmit = useCallback(
    (defaultFilter: any) => {
      setFilter(defaultFilter)
      onChange(defaultFilter)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  useEffect(() => {
    setFilter(value)
  }, [value])

  return (
    <FilterDataHolderContext.Provider
      value={{
        onFilterItemChange,
        getFilterItemValue,
        onFilterSubmit,
        onClickSubmit,
        onClearFilterSubmit
      }}>
      <form onSubmit={onFilterSubmit}>
        {children}
        <input type="submit" hidden />
      </form>
    </FilterDataHolderContext.Provider>
  )
}

Filter.SearchText = FilterItemSearchText
Filter.Select = FilterItemSelect
Filter.MultipleSelect = FilterItemMultipleSelect
Filter.Combobox = FilterItemCombobox
Filter.RangeDatePicker = FilterItemRangeDatePicker
Filter.Item = FilterItem
Filter.SearchOutFocus = FilterItemSearchOutFocus
Filter.Button = FilterButton

export default Filter
