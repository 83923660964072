import { useMemo } from 'react'
import { AnyVariables } from 'urql'
import { MultipleSelect } from '~/core/ui/MultipleSelect'
import { createFilterControl, createOptionFetcher } from '.'
import { FilterItemSelectPropTypeDocumentNode } from './FilterItemSelect'

export const FilterItemMultipleSelect = <
  Data = any,
  Variables extends AnyVariables = AnyVariables
>(
  props: FilterItemSelectPropTypeDocumentNode<Data, Variables> & {
    triggerFilterChange?: boolean
  }
) => {
  const { triggerFilterChange = true } = props
  const Control = useMemo(() => {
    const optionsFromDocumentNode = props.optionsFromDocumentNode
    return createFilterControl<
      FilterItemSelectPropTypeDocumentNode<Data, Variables>
    >(
      //@ts-ignore
      optionsFromDocumentNode
        ? //@ts-ignore
          createOptionFetcher((props) => {
            return (
              <MultipleSelect
                {...props}
                // async
                // loadOptions={fetchNewOption}
                value={props.value || null}
                onChange={(value) => {
                  //@ts-ignore
                  return props.onChange(value, { triggerFilterChange })
                }}
                options={props.options}
              />
            )
          })
        : (props) => (
            <MultipleSelect
              {...props}
              value={props.value}
              onChange={(value) =>
                //@ts-ignore
                props.onChange(value, { triggerFilterChange })
              }
            />
          )
    )
  }, [])
  return <Control {...props} />
}
