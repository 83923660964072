import { useCallback, useEffect, useState } from 'react'
import useContextGraphQL from '~/core/middleware/use-context-graphQL'
import { catchErrorFromGraphQL } from '~/core/utilities/catch-api-error'
import QueryTenantReferralSettingShow from '../graphql/query-tenant-referral'
import { ReferralSettingType } from '../types'
import { create } from 'zustand'

const useDataReferralSetting = create<{
  dataReferral?: ReferralSettingType
  setDataReferral: (data: ReferralSettingType) => void
}>((set) => ({
  setDataReferral: (data: ReferralSettingType) =>
    set((state) => ({ ...state, dataReferral: data }))
}))

const useReferralSetting = ({ suspend }: { suspend?: boolean } = {}) => {
  const { clientGraphQL } = useContextGraphQL()
  const { dataReferral, setDataReferral } = useDataReferralSetting()

  const fetchReferralSettingShow = useCallback(() => {
    return clientGraphQL
      .query(QueryTenantReferralSettingShow)
      .then(
        (result: {
          error: { graphQLErrors: Array<object> }
          data: { tenantReferralSettingShow: ReferralSettingType }
        }) => {
          if (result.error) {
            return catchErrorFromGraphQL({
              error: result.error
            })
          }

          const { tenantReferralSettingShow } = result.data || []
          setDataReferral(tenantReferralSettingShow)
          return
        }
      )
  }, [clientGraphQL])

  useEffect(() => {
    !suspend && fetchReferralSettingShow()
  }, [suspend])

  return { dataReferral, fetchReferralSettingShow }
}
export default useReferralSetting
