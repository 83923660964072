import { Dispatch, FC, SetStateAction, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton
} from 'react-share'
import { Button } from '~/core/ui/Button'
import { Dialog } from '~/core/ui/Dialog'
import { Divider } from '~/core/ui/Divider'
import If from '~/core/ui/If'
import { Input } from '~/core/ui/Input'
import { InputRightElement } from '~/core/ui/InputElement'
import { InputGroup } from '~/core/ui/InputGroup'
import { SocialButton } from '~/core/ui/SocialButton'
import { TypographyText } from '~/core/ui/Text'
import { Tooltip } from '~/core/ui/Tooltip'
import { JOB_STATUS_ENUM } from '~/lib/features/jobs/utilities/enum'

const ShareJobModal: FC<{
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  url?: string
  urlReferral?: string
  sharePublic?: boolean
  shareInternal?: boolean
}> = ({
  open,
  setOpen,
  url = '',
  urlReferral = '',
  sharePublic,
  shareInternal
}) => {
  const { t } = useTranslation()
  const [isCopied, setIsCopied] = useState<boolean>(false)
  const [isCopiedInternal, setIsCopiedInternal] = useState<boolean>(false)
  const handleCopyToClipboard = useCallback(() => {
    setIsCopied(true)
    navigator.clipboard.writeText(url)

    setTimeout(() => setIsCopied(false), 1500)
  }, [url])

  const handleCopyReferralLinkToClipboard = useCallback(() => {
    setIsCopiedInternal(true)
    navigator.clipboard.writeText(urlReferral || '')

    setTimeout(() => setIsCopiedInternal(false), 1500)
  }, [urlReferral])

  const renderShareReferralView = () => {
    return (
      <div>
        <TypographyText className="mb-4 mt-1 text-sm text-gray-600">
          {`${t('referrals:open_jobs:share:descriptionInternal')}`}
        </TypographyText>
        <div>
          <InputGroup>
            <Input
              size="sm"
              className="!border-gray-300 pr-[78px]"
              isDisabled={true}
              value={urlReferral}
            />
            <InputRightElement className="!bottom-0 !right-0 !top-0">
              <Tooltip
                content={`${t('tooltip:linkCopied')}`}
                open={isCopiedInternal}
                classNameConfig={{ content: isCopiedInternal ? '' : 'hidden' }}>
                <Button
                  type="secondary"
                  className="rounded-s-none focus:!ring-0 focus:!ring-offset-0"
                  label={`${t('referrals:open_jobs:share:copy_button')}`}
                  iconMenus="Copy"
                  size="sm"
                  onClick={handleCopyReferralLinkToClipboard}
                />
              </Tooltip>
            </InputRightElement>
          </InputGroup>
        </div>
      </div>
    )
  }

  if (shareInternal && sharePublic)
    return (
      <Dialog
        open={open}
        size="sm"
        onOpenChange={setOpen}
        isDivider={false}
        isPreventAutoFocusDialog={true}
        label={`${t('referrals:open_jobs:share:title')}`}
        headingClassName="tablet:pb-0">
        <div>
          <TypographyText className="mb-5 mt-1 text-sm text-gray-600">
            {`${t('referrals:open_jobs:share:description')}`}
          </TypographyText>
          <div className="mb-5">
            <TypographyText className="mb-2 text-sm font-medium text-gray-900">
              {t('referrals:open_jobs:share:copy_link')}
            </TypographyText>
            <InputGroup>
              <Input
                size="sm"
                className="!border-gray-300 pr-[78px]"
                isDisabled={true}
                value={url}
              />
              <InputRightElement className="!bottom-0 !right-0 !top-0">
                <Tooltip
                  content={`${t('tooltip:linkCopied')}`}
                  open={isCopied}
                  classNameConfig={{ content: isCopied ? '' : 'hidden' }}>
                  <Button
                    type="secondary"
                    className="rounded-s-none focus:!ring-0 focus:!ring-offset-0"
                    label={`${t('referrals:open_jobs:share:copy_button')}`}
                    iconMenus="Copy"
                    size="sm"
                    onClick={handleCopyToClipboard}
                  />
                </Tooltip>
              </InputRightElement>
            </InputGroup>
          </div>
          <TypographyText className="mb-2 text-sm font-medium text-gray-900">
            {t('referrals:open_jobs:share:or_share_on')}
          </TypographyText>

          <div className="flex space-x-3">
            <FacebookShareButton url={url}>
              <SocialButton size="md" type="facebook" />
            </FacebookShareButton>

            <LinkedinShareButton url={url}>
              <SocialButton size="md" type="linkedin" />
            </LinkedinShareButton>

            <TwitterShareButton url={url}>
              <SocialButton size="md" type="twitter" />
            </TwitterShareButton>
          </div>
        </div>
        <Divider className="my-4" />
        <TypographyText className="text-lg font-medium text-gray-900">
          {t('referrals:open_jobs:share:titleInternal')}
        </TypographyText>
        {renderShareReferralView()}
      </Dialog>
    )
  if (sharePublic)
    return (
      <Dialog
        open={open}
        size="sm"
        onOpenChange={setOpen}
        isDivider={false}
        isPreventAutoFocusDialog={true}
        label={`${t('referrals:open_jobs:share:title')}`}
        headingClassName="tablet:pb-0">
        <div>
          <TypographyText className="mb-5 mt-1 text-sm text-gray-600">
            {`${t('referrals:open_jobs:share:description')}`}
          </TypographyText>
          <div className="mb-5">
            <TypographyText className="mb-2 text-sm font-medium text-gray-900">
              {t('referrals:open_jobs:share:copy_link')}
            </TypographyText>
            <InputGroup>
              <Input
                size="sm"
                className="!border-gray-300 pr-[78px]"
                isDisabled={true}
                value={url}
              />
              <InputRightElement className="!bottom-0 !right-0 !top-0">
                <Tooltip
                  content={`${t('tooltip:linkCopied')}`}
                  open={isCopied}
                  classNameConfig={{ content: isCopied ? '' : 'hidden' }}>
                  <Button
                    type="secondary"
                    className="rounded-s-none focus:!ring-0 focus:!ring-offset-0"
                    label={`${t('referrals:open_jobs:share:copy_button')}`}
                    iconMenus="Copy"
                    size="sm"
                    onClick={handleCopyToClipboard}
                  />
                </Tooltip>
              </InputRightElement>
            </InputGroup>
          </div>
          <TypographyText className="mb-2 text-sm font-medium text-gray-900">
            {t('referrals:open_jobs:share:or_share_on')}
          </TypographyText>

          <div className="flex space-x-3">
            <FacebookShareButton url={url}>
              <SocialButton size="md" type="facebook" />
            </FacebookShareButton>

            <LinkedinShareButton url={url}>
              <SocialButton size="md" type="linkedin" />
            </LinkedinShareButton>

            <TwitterShareButton url={url}>
              <SocialButton size="md" type="twitter" />
            </TwitterShareButton>
          </div>
        </div>
      </Dialog>
    )
  if (shareInternal)
    return (
      <Dialog
        open={open}
        size="sm"
        onOpenChange={setOpen}
        isDivider={false}
        isPreventAutoFocusDialog={true}
        label={`${t('referrals:open_jobs:share:titleInternal')}`}
        headingClassName="tablet:pb-0">
        {renderShareReferralView()}
      </Dialog>
    )
  return <div />
}

export default ShareJobModal
